"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionAnalysisOutputSchema = exports.RollInfoSchema = exports.RollSchema = void 0;
const zod_1 = require("zod");
exports.RollSchema = zod_1.z.object({
    sides: zod_1.z.number(),
    disadvantage: zod_1.z.boolean().optional(),
    advantage: zod_1.z.boolean().optional(),
});
exports.RollInfoSchema = zod_1.z.object({
    explanation: zod_1.z.string(),
    type: zod_1.z.enum(["str", "dex", "cha", "con", "wis"]),
    dc: zod_1.z.number(),
    roll: zod_1.z.array(zod_1.z.union([zod_1.z.number(), exports.RollSchema])),
});
exports.ActionAnalysisOutputSchema = zod_1.z.object({
    actionDescription: zod_1.z.string(),
    actionUsed: zod_1.z.string(),
    requiresRoll: zod_1.z.boolean(),
    rollInfo: exports.RollInfoSchema.optional(),
    actionTarget: zod_1.z.array(zod_1.z.string()).optional(),
    effectTarget: zod_1.z.array(zod_1.z.string()).optional(),
});
