import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import WelcomeToTheDeep from "./pages/WelcomeToTheDeep";
import MapGenDebug from "./pages/MapGenDebug";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/welcometothedeep" element={<WelcomeToTheDeep />} />
        <Route path="/map-gen-debug" element={<MapGenDebug />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
