import React, { useState, useEffect, useRef } from "react";
import {
  TerminalEntry,
  TerminalEntryProps,
} from "./components/terminal-entry/TerminalEntry";
import { isPresent } from "./utils/utility";
import {
  generateCharacterSummary,
  getWelcomeMessage,
  IntroFlowState,
  introFlowStates,
} from "./utils/intro-utils";
import {
  DEFAULT_CHARACTER,
  setCharacterAC,
  setCharacterStats,
} from "./utils/character-utils";
import { setCharacterBackground } from "./utils/character-utils";
import { MAX_CHAT_LENGTH } from "./data/chat";
import {
  applyConditions,
  applyDamage,
  initializeWorld,
  processRoll,
  sendGameMessage,
} from "./utils/game-utils";
import { initializeCharacter } from "./utils/character-utils";
import { CharacterBackground, CLASSES } from "./data/classes";
import { useCallback } from "react";
import { StatValue } from "./components/terminal-entry/stat-allocation/StatAllocation";
import { SidePanel } from "./components/side-panel/SidePanel";
import { Modal } from "./components/modal/Modal";
import {
  Action,
  ActionAnalysis,
  BodyLocation,
  Condition,
  DEFAULT_INVENTORY,
  Dice,
  DMActionsMessage,
  isArmor,
  isDice,
  isNumber,
  isWeapon,
  Item,
  RollInfo,
  World,
} from "@ai-dm/utils";
import { Character, isValidStat, Stat } from "@ai-dm/utils";
import { DiceRollProps } from "./components/terminal-entry/dice-roll/DiceRoll";

/**
 * Represents an action that is pending resolution, such as a dice roll check or damage roll.
 * This state is used to manage UI elements and ensure the player completes the necessary input before proceeding.
 */
interface PendingActionState {
  actionName: string;
  actionDescription: string;
  targetCreatureNames?: string[];
  requiresCheck: boolean;
  requiresDamageRoll: boolean;
  checkRolled: boolean;
  checkSucceeded: boolean;
  damageRolled: boolean;
  damageTotal?: number;
}

/**
 * Represents an action that is being processed after all required player input (rolls) has been completed.
 * This state ensures that all effects, conditions, and damage are correctly applied.
 *
 * @property {Action} action - The action being resolved.
 * @property {boolean | undefined} checkSuccess - Indicates whether the ability check was successful (if applicable).
 * @property {number | undefined} damageTotal - The total amount of damage dealt by the action (if applicable).
 * @property {string[]} targets - The list of target entity names affected by the action.
 * @property {string[]} effectTargets - The list of entities affected by non-damage effects of the action.
 */
interface ActionResolutionState {
  action: Action;
  checkSuccess?: boolean;
  damageTotal?: number;
  targets: string[];
  effectTargets: string[];
}

let socket: WebSocket | null;

export const App: React.FC = () => {
  /**
   * List of conversation entries (TerminalEntry) representing all user
   * and assistant messages in the chat history.
   */
  const [entries, setEntries] = useState<TerminalEntryProps[]>([]);
  const [pendingAction, setPendingAction] = useState<PendingActionState | null>(
    null
  );
  /**
   * Tracks the current user input in the text field.
   */
  const [userInput, setUserInput] = useState<string>("");
  const [flowState, setFlowState] = useState<IntroFlowState | "game">(
    "welcome"
  );
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const terminalRef = useRef<HTMLDivElement>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [pendingRoll, setPendingRoll] = useState<{
    type: Stat;
    dc: number;
  } | null>(null);
  const [sidePanelWidth, setSidePanelWidth] = useState(400);
  const [showCharacterSheetModal, setShowCharacterSheetModal] = useState(false);
  // Game State
  const [character, setCharacter] = useState<Character>(initializeCharacter);
  const [inventory, setInventory] = useState<Item[]>([]);
  const [world, setWorld] = useState<World>(initializeWorld);
  const [isAtBottom, setIsAtBottom] = useState(true);

  // Logging in
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [authMode, setAuthMode] = useState<"login" | "signup" | null>(null);

  const isGeneratingImageRef = useRef(false);
  const characterRef = useRef<Character>(character);
  const worldRef = useRef<World>(world);
  const inventoryRef = useRef<Item[]>(inventory);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const lastScrollPosition = useRef(0);

  const generateImage = useCallback(async (text: string) => {
    if (isGeneratingImageRef.current) return;
    isGeneratingImageRef.current = true;

    try {
      const response = await fetch("/generate-image", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error("Failed to generate image:", error);
    }

    isGeneratingImageRef.current = false;
  }, []);

  const checkIfAtBottom = useCallback(() => {
    if (terminalRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = terminalRef.current;

      const atBottom = scrollTop + clientHeight >= scrollHeight - 20;
      setIsAtBottom(atBottom);
      lastScrollPosition.current = scrollTop;
    }
  }, []);

  //#region USEEFFECT
  useEffect(() => {
    const terminalElement = terminalRef.current;
    if (terminalElement) {
      terminalElement.addEventListener("scroll", checkIfAtBottom);
      // Initial check, Runs once during initial render, since checkIfAtBottom never changes
      checkIfAtBottom();
      return () => {
        terminalElement.removeEventListener("scroll", checkIfAtBottom);
      };
    }
  }, [checkIfAtBottom]);

  useEffect(() => {
    if (terminalRef.current) {
      if (isAtBottom) {
        // only auto-scroll if user was already at the bottom
        const { scrollHeight, clientHeight } = terminalRef.current;
        terminalRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }
  }, [entries, isAtBottom]);

  useEffect(() => {
    characterRef.current = character;
  }, [character]);

  useEffect(() => {
    worldRef.current = world;
  }, [world]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken) {
      setFlowState("worldCreation");
      setEntries((prevEntries) => [
        {
          role: "assistant",
          content: "What would you like to call your world?",
        },
      ]);
    } else {
      setFlowState("welcome");
      setEntries(getWelcomeMessage());
    }

    const connectWebSocket = () => {
      socket = new WebSocket("ws://localhost:8080");

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onerror = (error) => {
        console.error("WebSocket Error:", error);
      };

      socket.onmessage = (event: MessageEvent) => {
        const data = event.data;

        if (typeof data === "string") {
          try {
            // Regular text will fail to parse as a json, and logic is handled in catch block
            const parsedData = JSON.parse(data);
            console.log("INCOMING DATA", JSON.stringify(parsedData, null, 2));
            // Generic Ability Checks
            if (parsedData.type === "roll_request") {
              if (isPresent(parsedData.actionAnalysis.rollInfo)) {
                setIsStreaming(false);

                const rollInfo = parsedData.actionAnalysis.rollInfo as RollInfo;
                const stat = (
                  isPresent(rollInfo.type) && isValidStat(rollInfo.type)
                    ? rollInfo.type
                    : "dex"
                ) as Stat;
                const characterStatValue = characterRef.current.stats[stat];
                const dc = rollInfo.dc;
                const { results, totalRoll, rollOutcome, rollContent } =
                  processRoll(rollInfo, characterStatValue, stat);

                setPendingRoll({ type: stat, dc: dc ?? 10 });

                setEntries((prevEntries) => [
                  ...prevEntries,
                  {
                    role: "assistant",
                    content: rollInfo.explanation,
                  },
                  {
                    role: "user",
                    content: rollContent,
                    type: "diceRoll",
                    diceRollProps: {
                      type: stat,
                      roll: (rollInfo.roll ?? []) as (number | Dice)[],
                      dc: dc ?? 10,
                      results: results,
                      totalRoll: totalRoll,
                      onRollReveal: () =>
                        onRollRevealAbilityCheck(
                          rollInfo,
                          rollContent,
                          results,
                          stat,
                          dc ?? 10,
                          rollOutcome
                        ),
                    },
                  },
                ]);
              }
            } else if (parsedData.type === "action") {
              const {
                actionDescription,
                actionUsed,
                requiresRoll,
                rollInfo,
                actionTarget,
                effectTarget,
              }: ActionAnalysis = parsedData.actionAnalysis;

              //
              if (actionUsed === "NotPossible") {
                setEntries((prev) => [
                  ...prev,
                  {
                    role: "assistant",
                    content: actionDescription,
                  },
                ]);
                return;
              }
              const foundAction = characterRef.current.actions.find(
                (a) => a.name === actionUsed
              );
              if (!foundAction) {
                setEntries((prev) => [
                  ...prev,
                  {
                    role: "assistant",
                    content: `The action <strong>${actionUsed}</strong> is not available in your capabilities.`,
                  },
                ]);
                return;
              }

              const resolutionState: ActionResolutionState = {
                action: foundAction,
                targets: actionTarget || [],
                effectTargets: effectTarget || [
                  worldRef.current.currentRoom || "",
                ],
              };

              // If no roll is required, resolve the action immediately.
              if (!requiresRoll) {
                resolveAction(resolutionState, true);
                return;
              }

              // Otherwise, set up the pending action state and proceed with roll logic
              setPendingAction({
                actionName: foundAction.name,
                actionDescription: actionDescription ?? "",
                targetCreatureNames: actionTarget,
                requiresCheck: !!foundAction.abilityCheck,
                requiresDamageRoll: !!foundAction.damage,
                checkRolled: false,
                checkSucceeded: false,
                damageRolled: false,
                damageTotal: 0,
              });
              // If rollInfo exists, process the check roll.
              if (isPresent(rollInfo)) {
                setIsStreaming(false);
                const checkStat = (
                  isPresent(rollInfo.type) && isValidStat(rollInfo.type)
                    ? rollInfo.type
                    : "dex"
                ) as Stat;
                const characterStatValue =
                  characterRef.current.stats[checkStat];
                const dc = rollInfo.dc ?? 10;
                const { results, totalRoll, rollOutcome, rollContent } =
                  processRoll(rollInfo, characterStatValue, checkStat);
                setPendingRoll({ type: checkStat, dc });
                setEntries((prevEntries) => [
                  ...prevEntries,
                  {
                    role: "assistant",
                    content: rollInfo.explanation,
                  },
                  {
                    role: "user",
                    content: rollContent,
                    type: "diceRoll",
                    diceRollProps: {
                      type: checkStat,
                      roll: (rollInfo.roll ?? []) as (number | Dice)[],
                      dc: dc,
                      results: results,
                      totalRoll: totalRoll,
                      onRollReveal: (description: string, result: number) =>
                        onRollRevealAction(
                          description,
                          result,
                          resolutionState,
                          checkStat,
                          dc ?? 10,
                          foundAction
                        ),
                    },
                  },
                ]);
              }
            } else if (parsedData.type === "dm_actions") {
              console.log("DM_ACTIONS", parsedData);
              const dmActions = parsedData as DMActionsMessage;
              for (const action of dmActions.actions) {
                if (
                  isPresent(worldRef.current.currentPOI?.rooms) &&
                  action.type === "room_movement"
                ) {
                  const targetRoom = worldRef.current.currentPOI?.rooms.find(
                    (room) => room.name === action.to_room
                  );

                  if (targetRoom) {
                    console.log(
                      `Moving rooms from ${worldRef.current.currentRoom} to ${targetRoom}`
                    );
                    setWorld((prevWorld) => ({
                      ...prevWorld,
                      currentRoom: action.to_room,
                    }));
                    setEntries((prevEntries) => {
                      const updatedEntries = [...prevEntries];

                      // If there is at least 1 entry, append DM actions to the last assistant entry
                      if (updatedEntries.length > 0) {
                        for (let i = updatedEntries.length - 1; i >= 0; i--) {
                          if (updatedEntries[i].role === "assistant") {
                            updatedEntries[i] = {
                              ...updatedEntries[i],
                              content:
                                updatedEntries[i].content +
                                `%DM_ACTION_START% ${characterRef.current.name} moves to ${action.to_room} %DM_ACTION_END%`,
                            };
                            break;
                          }
                        }
                      }

                      return updatedEntries;
                    });
                  } else {
                    console.error(
                      `Attempted to move to room "${action.to_room}" which doesn't exist in POI "${world.currentPOI?.name}"`
                    );
                  }
                }
              }
            } else if (parsedData.type === "message_stop") {
              setIsStreaming(false);
              console.log("Streaming Ended");
              setEntries((prevEntries) => {
                const updatedEntries = [...prevEntries];
                const lastEntryIndex = updatedEntries.length - 1;

                if (lastEntryIndex >= 1) {
                  const lastEntry = updatedEntries[lastEntryIndex]?.content;

                  if (lastEntry) {
                    console.log("generating image");
                    generateImage(lastEntry);
                  } else {
                    console.error(
                      "No valid text available for image generation"
                    );
                  }
                } else {
                  console.error("Not enough entries to generate an image");
                }

                return updatedEntries;
              });
              if (inputRef.current) {
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }
            } else {
              console.log("Unknown message type:", parsedData);
            }
          } catch (error) {
            setEntries((prevEntries) => {
              const updatedEntries = [...prevEntries];
              const lastEntryIndex = updatedEntries.length - 1;
              const lastEntry = updatedEntries[lastEntryIndex];

              if (lastEntry && lastEntry.role === "assistant") {
                updatedEntries[lastEntryIndex] = {
                  ...lastEntry,
                  content: lastEntry.content + data,
                };
              } else {
                updatedEntries.push({
                  role: "assistant",
                  content: data,
                });
              }
              return updatedEntries;
            });
          }
        }
      };

      socket.onclose = (event) => {
        console.log("WebSocket connection closed", event);
        setTimeout(() => {
          console.log("Reconnecting...");
          connectWebSocket();
        }, 3000);
      };
    };

    connectWebSocket();

    return () => {
      if (socket) socket.close();
    };
  }, [generateImage]);
  //#endregion

  const scrollToBottom = () => {
    const terminalElement = terminalRef.current;
    if (terminalElement) {
      setTimeout(() => {
        const { scrollHeight, clientHeight } = terminalElement;
        terminalElement.scrollTop = scrollHeight - clientHeight;
        setIsAtBottom(true);
      }, 0);
    }
  };

  //#region COMMANDS INSTANCE FUNCTIONS
  const COMMAND_LIST: string[] = ["!default", "!level", "!character"];

  const isCommand = (userInput: string): boolean => {
    const [action, ...params] = userInput.toLowerCase().split(" ");
    if (COMMAND_LIST.includes(action)) {
      return true;
    }
    return false;
  };

  const processCommands = (userInput: string) => {
    const [action, ...params] = userInput.toLowerCase().split(" ");
    switch (action) {
      case "!default":
        setCharacter(DEFAULT_CHARACTER);
        setInventory(DEFAULT_INVENTORY);
        setWorld((prevWorld) => ({ ...prevWorld, name: "Exandria" }));
        clearEntries();
        setEntries([
          {
            role: "assistant",
            content: "Welcome Adrian! What do you do?",
          },
        ]);
        setFlowState("game");
        setUserInput("");
        break;
      case "!level":
        const levelIncrease = parseInt(params[0]);
        if (!isNaN(levelIncrease)) {
          const newLevel = character.level + levelIncrease;
          const newTotalCP =
            character.character_points.total + 10 * levelIncrease;
          updateCharacter("level", newLevel);
          updateCharacter("character_points", {
            ...character.character_points,
            total: newTotalCP,
          });
        }
        setUserInput("");
        break;
      case "!character":
        setShowCharacterSheetModal(true);
        setUserInput("");
        break;
      default:
        console.log("Unknown command");
    }
  };
  //#endregion

  //#region ACTION INSTANCE FUNCTIONS
  const resolveAction = (
    resolution: ActionResolutionState,
    checkSuccess: boolean
  ) => {
    let updatedWorld = worldRef.current;
    let updatedCharacter = characterRef.current;
    const dmActionLines: string[] = [];
    if (checkSuccess) {
      // Apply targeted conditions if any.
      if (resolution.action.targetedConditions) {
        updatedWorld = applyConditions(
          resolution.targets,
          resolution.action.targetedConditions,
          updatedWorld
        );
        dmActionLines.push(
          `Applied ${resolution.action.targetedConditions
            .map((c) => c.name)
            .join(", ")} to ${resolution.targets.join(", ")}`
        );
      }
      // Apply self conditions.
      if (resolution.action.selfConditions) {
        updatedCharacter = {
          ...updatedCharacter,
          conditions: [
            ...updatedCharacter.conditions,
            ...resolution.action.selfConditions,
          ],
        };
        dmActionLines.push(
          `Applied ${resolution.action.selfConditions
            .map((c) => c.name)
            .join(", ")} to self`
        );
      }
      // Apply damage.
      if (resolution.damageTotal && resolution.damageTotal > 0) {
        updatedWorld = applyDamage(
          resolution.targets,
          resolution.damageTotal,
          updatedWorld
        );
        dmActionLines.push(
          `Dealt ${resolution.damageTotal} damage to ${resolution.targets.join(
            ", "
          )} via ${resolution.action.name}`
        );
      }
      // Update action usage.
      if (resolution.action.usesPerRest) {
        const updatedAction = {
          ...resolution.action,
          usesPerRest: {
            ...resolution.action.usesPerRest,
            used: resolution.action.usesPerRest.used + 1,
          },
        };
        updatedCharacter = {
          ...updatedCharacter,
          actions: updatedCharacter.actions.map((a) =>
            a.name === resolution.action.name ? updatedAction : a
          ),
        };
      }
    }
    // Update state.
    setWorld(updatedWorld);
    setCharacter(updatedCharacter);

    // Finalize by appending DM action lines into the assistant's entry.
    setEntries((prev) => {
      const lastEntry = prev[prev.length - 1];
      if (!lastEntry || lastEntry.role !== "assistant") {
        // push a new assistant entry
        setPendingAction(null);
        setPendingRoll(null);
        return [
          ...prev,
          {
            role: "assistant",
            content: dmActionLines
              .map((line) => `%DM_ACTION_START% ${line} %DM_ACTION_END%`)
              .join("\n"),
          },
        ];
      } else {
        // append to the existing assistant
        const updated = [...prev];
        updated[prev.length - 1] = {
          ...lastEntry,
          content:
            lastEntry.content +
            "\n" +
            dmActionLines
              .map((line) => `%DM_ACTION_START% ${line} %DM_ACTION_END%`)
              .join("\n"),
        };
        setPendingAction(null);
        setPendingRoll(null);
        return updated;
      }
    });

    // 3) Now we send a final message to the server so it can narrate the outcome
    const newEntries = [...entries]; // after we appended the DM action lines
    setIsStreaming(true);
    sendGameMessage(
      newEntries,
      {
        character: updatedCharacter,
        inventory,
        world: updatedWorld,
        // no previousRoll now, or you could set something if needed
      },
      socket,
      MAX_CHAT_LENGTH
    );
  };

  const updateLastDiceRollEntry = (newDiceRoll: DiceRollProps) => {
    setEntries((prevEntries) => {
      const lastIndex = prevEntries.length - 1;
      if (lastIndex >= 0 && prevEntries[lastIndex].type === "diceRoll") {
        const current = prevEntries[lastIndex].diceRollProps;
        let updatedDiceRollProps: DiceRollProps | DiceRollProps[];
        if (Array.isArray(current)) {
          updatedDiceRollProps = [...current, newDiceRoll];
        } else if (current) {
          updatedDiceRollProps = [current, newDiceRoll];
        } else {
          updatedDiceRollProps = newDiceRoll;
        }
        const updatedEntries = [...prevEntries];
        updatedEntries[lastIndex] = {
          ...updatedEntries[lastIndex],
          diceRollProps: updatedDiceRollProps,
        };
        return updatedEntries;
      }
      return prevEntries;
    });
  };

  /**
   * Handles the onRollReveal for ability check rolls.
   * Updates the entries and sends the game message after the roll is completed.
   */
  const onRollRevealAbilityCheck = (
    rollInfo: RollInfo,
    rollContent: string,
    results: { value: number }[],
    stat: Stat,
    dc: number,
    rollOutcome: string
  ) => {
    const newEntries: TerminalEntryProps[] = [
      ...entries,
      {
        role: "assistant",
        content: rollInfo.explanation,
      },
      {
        role: "user",
        content: rollContent,
      },
    ];
    setIsStreaming(true);
    sendGameMessage(
      newEntries,
      {
        character: characterRef.current,
        inventory: inventoryRef.current,
        world: worldRef.current,
        previousRoll: {
          dc,
          roll: results.reduce((total, item) => total + item.value, 0),
          stat,
          outcome: rollOutcome,
        },
      },
      socket,
      MAX_CHAT_LENGTH
    );
    setPendingRoll(null);
  };

  /**
   * Handles the onRollReveal for action rolls, including damage calculation.
   * It determines if the check was successful, and if so, initiates a damage roll.
   */
  const onRollRevealAction = (
    description: string,
    result: number,
    resolutionState: ActionResolutionState,
    checkStat: Stat,
    dc: number,
    foundAction: Action
  ) => {
    const checkSuccess = result >= dc;
    resolutionState.checkSuccess = checkSuccess;

    if (!checkSuccess || !foundAction.damage) {
      // If check failed or no damage roll is needed, resolve the action immediately.
      resolveAction(resolutionState, checkSuccess);
      return;
    }

    // Mark check as completed in pending action state
    setPendingAction((prev) =>
      prev ? { ...prev, checkRolled: true, checkSucceeded: checkSuccess } : null
    );

    // Prepare damage roll info
    const damageRollInfo = {
      explanation: `Roll damage for ${foundAction.name}`,
      type: foundAction.abilityCheck?.stat[0] || "str",
      dc: 0,
      roll: foundAction.damage.map((r) => {
        if (typeof r === "number") {
          return r;
        } else if (isNumber(r)) {
          return r.valueOf();
        } else if (isDice(r)) {
          return r;
        } else {
          throw new Error(`Unexpected roll element: ${JSON.stringify(r)}`);
        }
      }),
    };

    // Process damage roll
    const { results, totalRoll: damageTotal } = processRoll(
      damageRollInfo,
      characterRef.current.stats[damageRollInfo.type as Stat],
      damageRollInfo.type as Stat
    );

    setPendingRoll({ type: damageRollInfo.type as Stat, dc: 0 });

    // Update the existing dice roll entry with damage roll details
    updateLastDiceRollEntry({
      type: damageRollInfo.type as Stat,
      roll: (damageRollInfo.roll ?? []) as (number | Dice)[],
      dc: 0,
      results,
      totalRoll: damageTotal,
      onRollReveal: (description: string, damageTotal: number) => {
        resolutionState.damageTotal = damageTotal;
        resolveAction(resolutionState, checkSuccess);
      },
      explanation: damageRollInfo.explanation,
    });
  };

  //#endregion

  //#region USER INPUT INSTANCE FUNCTIONS
  const processUserInput = () => {
    if (!userInput.trim() || isStreaming) return;
    if (isCommand(userInput)) {
      processCommands(userInput);
    } else if (flowState === "game") {
      const newEntries: TerminalEntryProps[] = [
        ...entries,
        { role: "user", content: userInput },
        { role: "assistant", content: "" },
      ];
      setEntries(newEntries);
      setIsStreaming(true);
      sendGameMessage(
        newEntries,
        {
          character: characterRef.current,
          inventory,
          world: worldRef.current,
        },
        socket,
        MAX_CHAT_LENGTH
      );
      scrollToBottom();
    } else if (introFlowStates.includes(flowState)) {
      processIntroFlow();
    }

    setUserInput("");
    if (flowState === "game") {
      setIsStreaming(true);
    } else {
      setIsStreaming(false);
    }
  };

  const handleUndo = () => {
    setIsStreaming(false);
    setPendingRoll(null);
    setEntries((prevEntries) => {
      if (prevEntries.length >= 1) {
        return prevEntries.slice(0, -1);
      }
      return prevEntries;
    });
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleClear = () => {
    setEntries((prevEntries) => {
      return [prevEntries[0]];
    });
  };

  const isLocationOccupied = (
    inventory: Item[],
    location: BodyLocation
  ): boolean => {
    return inventory.some(
      (i) => i.equipped && isArmor(i) && i.location === location
    );
  };

  // can an item be equipped (is a slot already taken)
  const tryEquipItem = (
    inventory: Item[],
    itemToEquip: Item
  ): { success: boolean; inventory: Item[]; message?: string } => {
    // item isn't equipable, return early
    if (!itemToEquip.equipable) {
      return {
        success: false,
        inventory,
        message: "This item cannot be equipped",
      };
    }

    // We'll do your slot check logic or "two hand" logic here.
    // The snippet below is simplified to ensure only 1 item per location
    // (excluding up to two 'Hand' items).
    const newInventory = [...inventory];
    const itemIndex = newInventory.findIndex(
      (item) => item.name === itemToEquip.name
    );
    if (itemIndex === -1) {
      return { success: false, inventory, message: "Item not found" };
    }
    // If item is already equipped, unequip it
    if (newInventory[itemIndex].equipped) {
      newInventory[itemIndex] = { ...itemToEquip, equipped: false };
      return { success: true, inventory: newInventory, message: "Unequipped" };
    }

    // Otherwise, try equipping it.
    // If it's a 'Hand' location, you can have up to 2 items in 'Hand'.
    // If any other location, can only equip one item in that location.
    // This is a simple example:
    if (
      isArmor(itemToEquip) &&
      isLocationOccupied(newInventory, itemToEquip.location)
    ) {
      return {
        success: false,
        inventory,
        message: `Cannot equip item – ${itemToEquip.location} slot is occupied`,
      };
    }
    // If it's a weapon in 'Hand' (or location is 'Hand'), allow up to 2
    // We'll do a quick check if we already have 2 items in the 'Hand'
    if (isWeapon(itemToEquip) && itemToEquip.location === "Hand") {
      const handsEquipped = newInventory.filter(
        (i) => i.equipped && isWeapon(i) && i.location === "Hand"
      ).length;
      if (handsEquipped >= 2) {
        return {
          success: false,
          inventory,
          message: "Cannot equip – both hands are occupied",
        };
      }
    }

    newInventory[itemIndex] = { ...itemToEquip, equipped: true };
    return {
      success: true,
      inventory: newInventory,
      message: "Equipped",
    };
  };
  const handleEquipToggle = (itemToUpdate: Item) => {
    const result = updateInventory(inventory, itemToUpdate);
    if (result.success) {
      setInventory(result.inventory);

      // Recalculating AC, actions, and conditions
      setCharacter((prevChar) => {
        const newChar = { ...prevChar };
        newChar.ac = setCharacterAC(newChar, result.inventory);
        newChar.actions = setCharacterActions(newChar, result.inventory);
        newChar.conditions = setCharacterConditions(newChar, result.inventory);

        return newChar;
      });
    }
  };
  //#endregion

  //#region SETTERS
  const updateCharacter = (key: keyof Character, value: any) => {
    setCharacter((prevCharacter) => ({
      ...prevCharacter,
      [key]: value,
    }));
  };

  const updateWorld = (key: keyof World, value: any) => {
    setWorld((prevWorld) => ({
      ...prevWorld,
      [key]: value,
    }));
  };

  const setCharacterActions = (
    character: Character,
    inventory: Item[]
  ): Action[] => {
    const actions: Action[] = [];

    actions.push({
      name: "Unarmed Strike",
      description:
        "A basic melee attack using your fists or body. Requires a d20 strength check.",
      usable: true,
      abilityCheck: {
        stat: ["str"],
      },
      damage: [Math.max(1 + character.stats.str, 1)],
      range: "Close",
    });

    for (const ability of character.abilities) {
      if (isPresent(ability.actions)) {
        for (const action of ability.actions) {
          actions.push(action);
        }
      }
    }

    for (const item of inventory) {
      if (isPresent(item.actions)) {
        for (const action of item.actions) {
          actions.push(action);
        }
      }
    }

    return actions;
  };

  const setCharacterConditions = (
    character: Character,
    inventory: Item[]
  ): Condition[] => {
    const conditions: Condition[] = [];

    for (const ability of character.abilities) {
      if (isPresent(ability.conditions)) {
        for (const condition of ability.conditions) {
          conditions.push(condition);
        }
      }
    }

    for (const item of inventory) {
      if (isPresent(item.conditions)) {
        if (item.conditions.mustBeEquipped) {
          if (item.equipped) {
            for (const condition of item.conditions.conditionList) {
              conditions.push(condition);
            }
          }
        } else {
          for (const condition of item.conditions.conditionList) {
            conditions.push(condition);
          }
        }
      }
    }

    return conditions;
  };

  const updateInventory = (
    inventory: Item[],
    itemToUpdate: Item
  ): {
    success: boolean;
    inventory: Item[];
    message?: string;
  } => {
    // equippable item logic
    if (itemToUpdate.equipable) {
      return tryEquipItem(inventory, itemToUpdate);
    }
    // just update the item for non-equipable
    const newInventory = [...inventory];
    const itemIndex = newInventory.findIndex(
      (item) => item.name === itemToUpdate.name
    );

    if (itemIndex !== -1) {
      newInventory[itemIndex] = itemToUpdate;
      return {
        success: true,
        inventory: newInventory,
      };
    }

    return {
      success: false,
      inventory,
      message: "Item not found in inventory",
    };
  };

  //#endregion

  //#region INTRO FLOW
  const processIntroFlow = async () => {
    if (flowState === "welcome") {
      if (userInput.toLowerCase() === "login") {
        setAuthMode("login");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter your username." },
        ]);
        setFlowState("username");
      } else if (userInput.toLowerCase() === "signup") {
        setAuthMode("signup");
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          { role: "assistant", content: "Please enter a new username." },
        ]);
        setFlowState("username");
      } else {
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: userInput },
          {
            role: "assistant",
            content: "Please type 'login' or 'signup' to proceed.",
          },
        ]);
      }
    } else if (flowState === "username") {
      setUsername(userInput);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your password." },
      ]);
      setFlowState("password");
    } else if (flowState === "password") {
      setPassword(userInput);
      try {
        const endpoint = authMode === "login" ? "/login" : "/signup";
        const response = await fetch(`http://localhost:3000${endpoint}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password: userInput }),
        });
        const data = await response.json();

        if (response.ok && data.token) {
          localStorage.setItem("userToken", data.token);
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content:
                authMode === "login"
                  ? "Login successful. Welcome back! What would you like to name your world?"
                  : "Signup successful. Welcome to your world creation! What would you like to name your world?",
            },
          ]);
          setFlowState("worldCreation");
        } else {
          setEntries((prevEntries) => [
            ...prevEntries,
            { role: "user", content: "*****" },
            {
              role: "assistant",
              content: `Authentication failed: ${data.message}. Please try again.`,
            },
          ]);
          setFlowState("welcome");
        }
      } catch (error) {
        console.error("Authentication Error:", error);
        setEntries((prevEntries) => [
          ...prevEntries,
          { role: "user", content: "*****" },
          {
            role: "assistant",
            content:
              "No associated account. Please reach out to me@playzain.com for closed alpha access. Get excited for an endless adventure in your own fantasy world! Build relationships with npcs. Conquer dangerous dungeons. Cross realms. Defeat monsters. Build your own realm. Do anything you want. ",
          },
        ]);
        setFlowState("welcome");
      }
      setAuthMode(null);
    } else if (flowState === "worldCreation") {
      setWorld((prevWorld) => ({ ...prevWorld, name: userInput }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please select a class:",
          type: "classSelection",
          modalSelectorProps: {
            type: "classSelection",
            options: CLASSES.map((cls) => cls.name),
            onSelect: (sel) => handleSelection(sel, "classSelection"),
            selectedOption: selectedClass,
          },
        },
      ]);
      setFlowState("classSelection");
    } else if (flowState === "classSelection") {
      // handled by handleSelection
    } else if (flowState === "name") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        name: userInput,
        description: `Name: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Now, let's allocate your character's stats:",
          type: "statAllocation",
          statAllocationProps: {
            onComplete: handleStatAllocationComplete,
          },
        },
      ]);
      setFlowState("statAllocation");
    } else if (flowState === "statAllocation") {
      // handled by handleStatAllocation
    } else if (flowState === "gender") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        { role: "assistant", content: "Please enter your character's height:" },
      ]);
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nGender: ${userInput}`,
      }));
      setFlowState("height");
    } else if (flowState === "height") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHeight: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's hair color:",
        },
      ]);
      setFlowState("hair");
    } else if (flowState === "hair") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nHair Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's skin color:",
        },
      ]);
      setFlowState("skinColor");
    } else if (flowState === "skinColor") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: prevCharacter.description + `\nSkin Color: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's facial appearance:",
        },
      ]);
      setFlowState("facialAppearance");
    } else if (flowState === "facialAppearance") {
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description:
          prevCharacter.description + `\nFacial Appearance: ${userInput}`,
      }));
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
        {
          role: "assistant",
          content: "Please enter your character's bodily appearance:",
        },
      ]);
      setFlowState("bodilyAppearance");
    } else if (flowState === "bodilyAppearance") {
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: userInput },
      ]);

      const summary = await generateCharacterSummary(
        character.description + `\nBodily Appearance: ${userInput}`
      );
      setCharacter((prevCharacter) => ({
        ...prevCharacter,
        description: summary,
      }));

      clearEntries();
      setEntries([
        {
          role: "assistant",
          content:
            "Congratulations, your character is complete! You are standing outside of a dungeon. What do you do?",
        },
      ]);
      setFlowState("game");
    }
  };

  const handleSelection = (selection: string, selectionType: string) => {
    const selectedClass = CLASSES.find((cls) => cls.name === selection);
    if (isPresent(selectedClass)) {
      setCharacter((prevCharacter) =>
        setCharacterBackground(
          prevCharacter,
          selectedClass as CharacterBackground
        )
      );
      setInventory(selectedClass?.startingEquipment || []);
      setSelectedClass(selection);
      setEntries((prevEntries) => [
        ...prevEntries,
        { role: "user", content: `${selection} selected` },
        { role: "assistant", content: "Please enter your character's name:" },
      ]);
      setFlowState("name");
      if (inputRef.current) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }
  };

  const handleStatAllocationComplete = (stats: StatValue[]) => {
    setCharacter((prevCharacter) =>
      setCharacterStats(prevCharacter, stats, inventory)
    );
    setEntries((prevEntries) => [
      ...prevEntries,
      { role: "user", content: "Stats allocated" },
      {
        role: "assistant",
        content: "Great! Now, please enter your character's gender:",
      },
    ]);
    setFlowState("gender");
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  //#endregion

  const getInputPlaceholder = () => {
    if (isStreaming) return "Please wait...";
    if (pendingRoll !== null) return "Please roll...";
    switch (flowState) {
      case "classSelection":
        return "Please select a class...";
      case "backgroundSelection":
        return "Please select a background...";
      case "statAllocation":
        return "Please allocate your stats...";
      default:
        return "Type here...";
    }
  };

  const clearEntries = () => {
    setEntries([]);
  };

  return (
    <main className="flex h-screen bg-background text-text font-mono">
      <div className="flex-1 flex flex-col justify-start mr-8 p-8 min-w-[200px]">
        <div
          className="overflow-y-auto flex-grow scrollbar-hide"
          ref={terminalRef}
        >
          {entries.map((entry, index) => (
            <TerminalEntry
              key={index}
              content={entry.content}
              role={entry.role}
              type={entry.type}
              modalSelectorProps={entry.modalSelectorProps}
              statAllocationProps={entry.statAllocationProps}
              diceRollProps={entry.diceRollProps}
            />
          ))}
        </div>
        <div className="mt-auto w-full">
          <div className="flex items-start bg-secondary p-2 rounded">
            <span
              className={`text-xl mr-2 ${
                userInput ? "text-white" : "text-gray-400"
              }`}
              style={{
                alignSelf: userInput ? "start" : "center", // Align dynamically
              }}
            >
              &gt;
            </span>
            <textarea
              className="w-full bg-secondary text-white border-none outline-none font-mono text-xl resize-none overflow-y-auto"
              ref={inputRef}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "auto";
                target.style.height = `${Math.min(target.scrollHeight, 200)}px`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  processUserInput();
                }
              }}
              placeholder={getInputPlaceholder()}
              rows={1}
              disabled={
                isStreaming ||
                flowState === "classSelection" ||
                flowState === "backgroundSelection" ||
                flowState === "statAllocation" ||
                pendingRoll !== null
              }
            />
          </div>
        </div>
      </div>
      <SidePanel
        width={sidePanelWidth}
        setWidth={setSidePanelWidth}
        imageUrl={imageUrl || "/images/zain-title.png"}
        character={character}
        world={world}
        inventory={inventory}
        updateCharacter={updateCharacter}
        onEquipToggle={handleEquipToggle}
        onUndo={handleUndo}
        onClear={handleClear}
        updateWorld={updateWorld}
      />
      {showCharacterSheetModal && (
        <Modal
          onClose={() => setShowCharacterSheetModal(false)}
          characterSheetModalProps={{
            character,
          }}
        />
      )}
    </main>
  );
};

export default App;
