import {
  Action,
  Character,
  Condition,
  Effect,
  isPresent,
  Metadata,
  RollInfo,
  Room,
  Stat,
} from "@ai-dm/utils";
import { World } from "@ai-dm/utils";
import { Armor, Item, Weapon } from "@ai-dm/utils";
import { DELPHIAN_TOMB } from "../data/dungeon";
import { TerminalEntryProps } from "../components/terminal-entry/TerminalEntry";

export interface MessageStringParam {
  content: string;
  role: "user" | "assistant";
}

const convertToMessageParam = (
  entry: TerminalEntryProps
): MessageStringParam => ({
  content: entry.content ?? "",
  role: entry.role,
});

export const sendGameMessage = (
  entries: TerminalEntryProps[],
  metadata: Metadata,
  socket: WebSocket | null,
  maxChatLength: number = 30
) => {
  if (!socket) return;

  const filteredEntries = [...entries];
  if (
    filteredEntries.length > 0 &&
    filteredEntries[filteredEntries.length - 1].role === "assistant"
  ) {
    filteredEntries.pop();
  }

  const recentMessages = filteredEntries
    .slice(-maxChatLength)
    .map(convertToMessageParam);

  const websocketMessage = {
    messages: recentMessages,
    metadata: metadata,
  };

  console.log("WebSocket message sent:", websocketMessage);
  socket.send(JSON.stringify(websocketMessage));
};

export const initializeWorld = (): World => ({
  name: "",
  currentPOI: DELPHIAN_TOMB,
  currentRoom: "Tomb Entrance",
  currentHex: { x: 0, y: 0 },
});

interface RollResult {
  results: { value: number; type: string }[];
  totalRoll: number;
  rollOutcome: string;
  rollContent: string;
}

interface RollResult {
  results: { value: number; type: string }[];
  totalRoll: number;
  rollOutcome: string;
  rollContent: string;
}

export const processRoll = (
  rollInfo: RollInfo,
  characterStat: number,
  stat: Stat
): RollResult => {
  const dc = rollInfo.dc;
  if (!isPresent(rollInfo.roll)) {
    throw Error("rollInfo.roll undefined");
  }

  // Add character stat if it's not zero
  if (characterStat !== 0) {
    rollInfo.roll.push(characterStat);
  }

  const results: { value: number; type: string }[] = [];
  let totalRoll = 0;
  let rollOutcome = "succeeding";

  rollInfo.roll.forEach((item) => {
    if (typeof item === "number") {
      results.push({ value: item, type: "constant" });
      totalRoll += item;
    } else if (
      item &&
      typeof item === "object" &&
      "sides" in item &&
      isPresent(item.sides)
    ) {
      const roll = Math.floor(Math.random() * item.sides) + 1;
      results.push({ value: roll, type: `d${item.sides}` });
      totalRoll += roll;
    } else {
      console.error("Unexpected roll item:", item);
    }
  });

  const d20Result = results.find((r) => r.type === "d20")?.value;
  const success = totalRoll >= (dc ?? 10);
  rollOutcome = success ? "succeeding" : "failing";

  if (d20Result === 20) {
    rollOutcome = "critically succeeding";
  } else if (d20Result === 1) {
    rollOutcome = "critically failing";
  }

  const rollContent = `I am rolling a DC ${dc} ${stat.toUpperCase()} check. I rolled a ${totalRoll}, ${rollOutcome}.`;

  return {
    results,
    totalRoll,
    rollOutcome,
    rollContent,
  };
};

interface ActionResolutionState {
  action: Action;
  checkSuccess?: boolean;
  damageTotal?: number;
  targets: string[];
  effectTargets: string[];
}

export const applyConditions = (
  targets: string[],
  conditions: Condition[],
  world: World
): World => {
  const updatedWorld = { ...world };
  if (!updatedWorld.currentPOI?.rooms) return world;

  return {
    ...updatedWorld,
    currentPOI: {
      ...updatedWorld.currentPOI,
      rooms: updatedWorld.currentPOI.rooms.map((room) => ({
        ...room,
        creatures: room.creatures.map((creature) => ({
          ...creature,
          conditions: targets.includes(creature.name)
            ? [...(creature.conditions || []), ...conditions]
            : creature.conditions,
        })),
      })),
    },
  };
};

export const applyDamage = (
  targets: string[],
  damage: number,
  world: World
): World => {
  const updatedWorld = { ...world };
  if (!updatedWorld.currentPOI?.rooms) return world;

  return {
    ...updatedWorld,
    currentPOI: {
      ...updatedWorld.currentPOI,
      rooms: updatedWorld.currentPOI.rooms.map((room) => ({
        ...room,
        creatures: room.creatures.map((creature) => {
          if (!targets.includes(creature.name)) return creature;

          const newHP = Math.max(creature.hp - damage, 0);
          return {
            ...creature,
            dead: newHP === 0,
          };
        }),
      })),
    },
  };
};
